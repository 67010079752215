import { Link } from "gatsby"
import React from "react"
import "../assets/css/main.css"
import { EarningEstimations } from "./earnings-estimations"
import TrackingDebtImage from "../../static/assets/track-debt-pay-off-down.svg"
import OverviewMoneyImage from "../../static/assets/undebtme-overview-screen.svg"
import OverviewTabImage from "../../static/assets/overview.png"
import ExtraMoneyTabImage from "../../static/assets/extra-money-tab.png"
import {
  RiCheckboxCircleLine,
  RiLock2Fill,
  RiMoneyDollarCircleFill,
  RiPercentFill,
  RiBankCardFill,
  RiMedalFill,
} from "react-icons/ri"

export const ValueProposition = () => {
  return (
    <main className="main" role="main">
      <div className="section_wrapper center-xs">
        <div className="section_content">
          <EarningEstimations />
        </div>
      </div>
      <div className="generate_money_section">
        <div className="row center-xs">
          <div className="col-sm-12 col-lg-6">
            <h1 className="title">Obtén dinero extra sin darte cuenta</h1>
            <p>
              Undebtme acumula pequeñas cantidades de dinero a lo largo del mes
              y las transforma en una gran contribución mensual que va directo a
              tu meta financiera - Pagar o ahorrar.
            </p>
            <img
              style={{ maxWidth: "100%" }}
              alt="Dinero extra generado"
              src={OverviewMoneyImage}
            />
          </div>
          <div className="col-sm-12 col-lg-6 ">
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6">
                <div className="list-item">
                  <div className="text">
                    <RiMoneyDollarCircleFill color="green" size={78} />
                    <h1>Redondeos</h1>
                    <p>
                      Cada vez que hagas una compra, redondearemos su importe al
                      siguiente dígito para que acumules dinero poco a poco.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6">
                <div className="list-item">
                  <div className="text">
                    <RiPercentFill color="blue" size={78} />
                    <h1>% de tus ingresos</h1>
                    <p>
                      Por cada ingreso que recibas, añadiremos a tu balance un
                      porcentaje de la cantidad recibida.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6">
                <div className="list-item">
                  <div className="text">
                    <RiBankCardFill color="black" size={78} />
                    <h1>Descuentos instantáneos</h1>
                    <p>
                      Undebtme Card es una tarjeta de débito gratuita con
                      descuentos instantáneos en tus tiendas o sitios favoritos.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6">
                <div className="list-item">
                  <div className="text">
                    <RiMedalFill color="gold" size={78} />
                    <h1>Gana dinero por logros</h1>
                    <p>
                      Acumula dinero por logros financieros. ¿Liquidaste tu
                      primera tarjeta de crédito?. ¿Elaboraste tu presupuesto?
                      Undebtme te regalará un monto por ese logro y lo sumará a
                      tu balance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overview-tab">
        <div className="row middle-xs">
          <div className="row center-xs col-sm-12 col-lg-6">
            <img
              style={{ maxWidth: "80%" }}
              alt="Estadísticas"
              src={OverviewTabImage}
            />
          </div>
          <div className="center-xs start-sm col-xs-12 col-sm-6">
            <h1 className="title">Obtenga todas sus estadísticas</h1>
            <p>
              <span role="img" aria-label="money-bag">
                💰
              </span>
              Dinero extra generado mediante los métodos automáticos.
            </p>
            <p>
              <span role="img" aria-label="money-bag">
                💰
              </span>
              Conoce el total pagado y restante de la deuda actual.
            </p>
            <p>
              <span role="img" aria-label="money-bag">
                💰
              </span>
              Evalua la tendencia de tus gastos.
            </p>
          </div>
        </div>
      </div>
      <div className="debt_tracking_section ">
        <div className="row center-xs middle-xs">
          <div className="col-sm-12 col-lg-6">
            <h1 className="title">Observa cómo tu deuda disminuye</h1>
            <p>
              Lleva un seguimiento de tus pagos. Observa como la deuda comienza
              a disminuir en el tiempo.
            </p>
          </div>
          <div className="col-xs-12 col-sm-6">
            <img
              style={{ maxWidth: "75%" }}
              alt="Observa como disminuye tu deuda"
              src={TrackingDebtImage}
            />
          </div>
        </div>
      </div>
      <div className="more_money_section">
        <div className="row center-xs">
          <div className="row center-xs">
            <div className="col-sm-12 col-lg-9">
              <h1 className="title">Incrementar aún más tus ingresos</h1>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12">
                <div className="list-item">
                  <div className="text">
                    <h1>
                      ¿Prefieres empezar tu negocio o un trabajo alternativo?
                    </h1>
                    <p>
                      Tablero personalizado de trabajos alternativos basados en
                      tu perfil y habilidades.
                    </p>
                  </div>
                </div>
                <img
                  style={{ maxWidth: "100%" }}
                  alt="Dinero extra"
                  src={ExtraMoneyTabImage}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row center-xs">
          <div className="row center-xs">
            <div className="col-sm-12 col-lg-9">
              <h1 className="title">Mejores Hábitos</h1>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div className="list-item">
                  <div className="text">
                    <h1>Desarrolle mejores hábitos con el dinero</h1>
                    <p>Tome decisiones financieras informadas.</p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6">
                <div className="value-proposition-container blue">
                  <h3 className="value-title">Educación Financiera</h3>
                  <p>
                    Optimize su dinero con información detallada, consejos
                    practicos y personalizados.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="security-container">
        <div className="row security-container-content middle-xs">
          <div className="col-xs-12 col-sm-6  middle-sm center-xs">
            <RiLock2Fill color="white" size={64} />
            <h1>Nos tomamos en serio la seguridad</h1>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="row middle-lg start-lg center-xs">
              <RiCheckboxCircleLine color="green" size={48} />
              <h3>Cifrado grado bancario de 256-bits.</h3>
            </div>
            <div className="row middle-lg start-lg center-xs">
              <RiCheckboxCircleLine color="green" size={48} />
              <h3>Tu información es read-only (Solo lectura).</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-xs-12 col-sm-4"
        style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 20 }}
      >
        <div className="pricing-container margin-top">
          <h3>Planes & Precios</h3>
          <p>Elige el plan que mejor se adapta a ti.</p>
          <Link to="/pricing">
            <button>Ver Precios</button>
          </Link>
        </div>
      </div>
    </main>
  )
}
