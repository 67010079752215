import React, { useMemo, useState } from "react"
import { calcSavedInterests, calcSavingTime } from "./util"
import { Link } from "gatsby"

const defaultState = {
  originalAmount: 0,
  debtTermInYears: 0,
  anualInterestFee: 0,
  extraMonthlyPayment: 0,
}

const MONTHS = 12

const LOCALE_CURRENCY = {
  usd: "us-US",
  cop: "es-CO",
}

const CURRENCY_ID = {
  cop: "COP",
  usd: "USD",
}

const EarlyPayoffCalculator = () => {
  const [formValues, setFormValues] = useState(defaultState)
  const [currency, setCurrency] = useState({
    value: LOCALE_CURRENCY.cop,
    name: "COP",
  })

  const handleChange = event => {
    const { name, value } = event.target
    setFormValues({ ...formValues, [name]: value })
  }

  const savingsTime = useMemo(
    () =>
      calcSavingTime(
        Number(formValues.originalAmount),
        Number(formValues.anualInterestFee),
        Number(formValues.debtTermInYears) * MONTHS,
        Number(formValues.extraMonthlyPayment)
      ),
    [formValues]
  )

  const savedInterests = useMemo(
    () =>
      calcSavedInterests(
        Number(formValues.originalAmount),
        Number(formValues.anualInterestFee),
        Number(formValues.debtTermInYears) * MONTHS,
        Number(formValues.extraMonthlyPayment)
      ),
    [formValues]
  )

  return (
    <div className="early-payoff-container grids col-1 sm-2">
      <div className="debt-row">
        <h1 className="title">Calculadora De Deuda Anticipada</h1>
        <p className="title subheading">
          Visualiza cuanto dinero en intereses y tiempo puedes ahorrar al hacer
          pagos adicionales.
        </p>
        <div>
          <p className="early-payoff-p">Monto original</p>
          <input
            type="number"
            min={0}
            className="early-payoff-input"
            name={"originalAmount"}
            value={formValues.originalAmount.toString()}
            onChange={handleChange}
          />
        </div>
        <div>
          <p className="early-payoff-p">Plazo Original (en años)</p>
          <input
            type="number"
            min={0}
            className="early-payoff-input"
            name={"debtTermInYears"}
            value={formValues.debtTermInYears}
            onChange={handleChange}
          />
        </div>
        <div>
          <p className="early-payoff-p">Tasa de interés (Mensual)</p>
          <input
            type="number"
            min={0}
            className="early-payoff-input"
            name={"anualInterestFee"}
            value={formValues.anualInterestFee.toString()}
            onChange={handleChange}
          />
        </div>
        <div>
          <p className="early-payoff-p">Pago Mensual Adicional</p>
          <input
            type="number"
            min={0}
            className="early-payoff-input"
            name={"extraMonthlyPayment"}
            value={formValues.extraMonthlyPayment}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="debt-row highlight">
        <div className="early-payoff-currency">
          <p>Moneda</p>
          <select
            name="currency"
            onChange={e => {
              setCurrency({ value: e.target.value, name: e.target.name })
            }}
          >
            <option value="es-CO">COP</option>
            <option value="us-US">USD</option>
          </select>
        </div>
        <h1 className="title resumen">Resumen</h1>
        <div className="resume-body">
          <div className="padding-bottom">
            <p className="early-payoff">Ahorro en intereses</p>
            <h1 className="savings">
              {`${savedInterests.toLocaleString(currency?.value, {
                maximumFractionDigits: 0,
              })} ${
                currency?.value === LOCALE_CURRENCY.cop
                  ? CURRENCY_ID?.cop
                  : CURRENCY_ID.usd
              } `}
            </h1>
          </div>
          <div>
            <p className="early-payoff">Ahorro total de tiempo</p>
            <h1 className="savings">
              <span>{savingsTime?.savedTime?.years || 0} Años</span>
            </h1>
            <h1 className="savings">
              <span>{savingsTime?.savedTime?.months || 0} Meses</span>
            </h1>
          </div>
          <p className="financial-freedom">
            Libre de deuda {savingsTime?.savedPercentageTime.toFixed(0) || 0}%
            más rápido 🎉🎉
          </p>
          {Boolean(formValues?.extraMonthlyPayment) && (
            <p className="early-payoff">
              {`Si pagas ${
                formValues?.extraMonthlyPayment || 0
              } adicionales al mes a
            partir de ahora, la deuda se liquidará en
            ${savingsTime?.savedTime?.years || 0} años y
            ${
              savingsTime?.savedTime?.months || 0
            } meses. Esto resulta en un ahorro de ${savedInterests.toLocaleString(
                currency?.value,
                {
                  maximumFractionDigits: 0,
                }
              )} en intereses.`}
            </p>
          )}
          <div className="create-account-container">
            <Link to="/subscribe">
              <button className="early-payoff-create-account col-xs-12 col-sm-12">
                Quiero Pagar Deudas Más Rápido
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EarlyPayoffCalculator
