import { Link } from "gatsby"
import React, { useState } from "react"
const isColombia = true
const colombianUSDChange = 5000
const DECIMAL_NUMBERS = isColombia ? colombianUSDChange : 1 // For Colombia

export const EarningEstimations = () => {
  const [inputs, setInputs] = useState([
    {
      date: "Abril 02",
      description: "Gasolina",
      monthlyAmount: 1,
      amount: "70596",
      category: "expense",
      percentage: 0,
      default: true,
    },
    {
      date: "Abril 05",
      description: "Alquiler",
      monthlyAmount: 1,
      amount: "2250260",
      category: "expense",
      percentage: 0,
      default: true,
    },
    {
      date: "Abril 06",
      description: "Factura Energía",
      monthlyAmount: 1,
      amount: "135015",
      category: "expense",
      percentage: 0,
      default: true,
    },
    {
      date: "Abril 08",
      description: "Factura Agua",
      monthlyAmount: 1,
      amount: "67507",
      category: "expense",
      percentage: 0,
      default: true,
    },
    {
      date: "Abril 10",
      description: "Internet",
      monthlyAmount: 1,
      amount: "90010",
      category: "expense",
      percentage: 0,
      default: true,
    },
    {
      date: "Abril 11",
      description: "Café",
      monthlyAmount: 3,
      amount: "6.956",
      category: "expense",
      percentage: 0,
      default: true,
    },
    {
      date: "Abril 10",
      description: "Nómina/Planilla (% destinado a la deuda)",
      monthlyAmount: 1,
      amount: "1160000",
      category: "income",
      percentage: 5,
      default: true,
    },
  ])

  let totalRoundups = 0

  const handleOnchange = (index, e) => {
    const { name, value } = e.target
    let newInputs = [...inputs]

    if (
      (name !== "description" && isNaN(value)) ||
      value < 0 ||
      value === "0"
    ) {
      newInputs[index][name] = 0
    } else {
      newInputs[index][name] = value
    }
    setInputs(newInputs)
  }
  const addItem = () => {
    setInputs([
      ...inputs,
      {
        date: "Abril",
        description: "Ingresa descripcion",
        monthlyAmount: 0,
        amount: 0,
        category: "expense",
        percentage: 0,
        default: false,
      },
    ])
  }
  const renderForm = () => {
    return inputs.map((item, index) => {
      let extraMoney = 0
      const isIncomeCategory = item.category === "income"

      const amount = item.amount !== 0 ? item?.amount?.slice(-3) : 0
      if (Number(item.amount) > 0) {
        if (isIncomeCategory) {
          extraMoney = Number(Number(item.amount) * item.percentage) / 100
        } else {
          extraMoney = DECIMAL_NUMBERS - Number(amount)

          extraMoney = extraMoney * item.monthlyAmount
        }
      }

      totalRoundups = totalRoundups + extraMoney
      return (
        <div
          className="grids col-4 sm-4 lg-4 between-lg item-row"
          key={`item-${index}`}
        >
          <div>
            {item.default ? (
              item.description
            ) : (
              <input
                type="text"
                name="description"
                onChange={e => handleOnchange(index, e)}
                value={item.description}
              />
            )}
          </div>
          <div>
            <input
              type="number"
              min={0}
              name={`${isIncomeCategory ? "percentage" : "monthlyAmount"}`}
              onChange={e => handleOnchange(index, e)}
              value={isIncomeCategory ? item.percentage : item.monthlyAmount}
            />
          </div>
          <div>
            <input
              name="amount"
              type="number"
              min={0}
              onChange={e => handleOnchange(index, e)}
              value={item.amount}
            />
          </div>
          <div className="highlighted">+{extraMoney}</div>
        </div>
      )
    })
  }

  return (
    <div className="col-sm-12 col-lg-12">
      <div className="estimations-header">
        <h1>¿Cuánto dinero extra puedes obtener con Undebtme?</h1>
        <div className="header-disclaimer">
          La siguiente estimación esta basada en solo dos métodos: redondeo &
          retención. Más abajo encontrará la sección con el detalle de los
          métodos que Undebtme utiliza.
        </div>
      </div>
      <div>Transacciones Mensuales</div>

      <div className="transactions-table">
        <div className="grids col-4 sm-4 lg-4">
          <div>Desc.</div>
          <div>Cant.Mensual</div>
          <div>Costo</div>
          <div>Generado</div>
        </div>

        {renderForm()}
        <div className="row between-lg between-md between-xs middle-lg middle-md middle-xs">
          <div>
            <button onClick={addItem} className="col-xs-12 col-sm-12">
              Agregar Item
            </button>
          </div>
          <div className="row between-lg between-sm between-xs middle-xs total-earnings">
            <p>Dinero extra:</p>
            <span className="title">{totalRoundups}</span>
            <p>COP</p>
          </div>
        </div>

        <div className="disclaimer">
          * Los montos están en Pesos Colombianos(COP). Por simplicidad se han
          agregado únicamente algunos ítems. Tú puedes agregar cuantos quieras
          para tener una estimación más precisa.
        </div>
        <div>
          <Link to="/pricing">
            <button className="create-account col-xs-12 col-sm-12">
              Crear Mi Cuenta Ahora
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
