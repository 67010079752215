export const calcMonthlyPayment = (principal, anualFee, months) => {
  let monthlyFee = Number(anualFee / 12 / 100)
  return (
    (Number(principal) * monthlyFee) /
    (1 - Math.pow(1 + monthlyFee, Number(-months)))
  )
}

export const calcSavingTime = (
  principal,
  anualFee,
  months,
  extraMonthlyPayment
) => {
  let monthlyFee = Number(anualFee) / 12 / 100
  let monthlyPayment = calcMonthlyPayment(principal, anualFee, months)
  let balance = Number(principal)

  let monthWithAditionalPayments = 0

  while (balance > 0 && monthWithAditionalPayments < months) {
    let interest = balance * monthlyFee
    let totalPayment = monthlyPayment + extraMonthlyPayment
    balance = balance + interest - totalPayment

    if (balance < 0) {
      balance = 0 // avoid negative balance
    }

    monthWithAditionalPayments++
  }

  let savingsTime = months - monthWithAditionalPayments
  let savedPercentageTime = (savingsTime / months) * 100

  const parsedSavedTime = convertMonthsToYearsAndMonths(savingsTime)
  return {
    savedTime: parsedSavedTime,
    savedPercentageTime: !isNaN(savedPercentageTime) ? savedPercentageTime : 0,
  }
}

export const calcSavedInterests = (
  principal,
  anualFee,
  months,
  extraMonthlyPayment
) => {
  let monthlyFee = anualFee / 12 / 100
  let monthlyPayment = calcMonthlyPayment(principal, anualFee, months)
  let totalInterestWithoutExtraPayments = 0
  let totalInterestWithExtraPayments = 0
  let balance = principal

  // Calc of interests without additional payments
  for (let i = 0; i < months; i++) {
    let interes = balance * monthlyFee
    totalInterestWithoutExtraPayments += interes
    balance = balance + interes - monthlyPayment
  }

  balance = principal // Restart balance
  let month = 0

  // Calc interests with additional payments
  while (balance > 0 && month < months) {
    let interest = balance * monthlyFee
    totalInterestWithExtraPayments += interest
    let totalPayment = monthlyPayment + extraMonthlyPayment
    balance = balance + interest - totalPayment

    if (balance < 0) {
      balance = 0 // Avoid negative balance
    }

    month++
  }

  let savedInterests =
    totalInterestWithoutExtraPayments - totalInterestWithExtraPayments

  return !isNaN(savedInterests) ? savedInterests : 0
}

export const convertMonthsToYearsAndMonths = totalMonths => {
  const monthsInYear = 12
  const years = Math.floor(totalMonths / monthsInYear)
  const remainingMonths = totalMonths % monthsInYear
  return {
    years,
    months: remainingMonths,
  }
}
