/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import { ValueProposition } from "../components/value-proposition"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import "../assets/css/flexboxgrid.min.css"
import EarlyPayoffCalculator from "../components/Calculator/EarlyPayoffCalculator"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 585, quality: 100)
          }
        }
        cta {
          ctaText
          ctaLink
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "blog-post" } } }
      limit: 6
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : ""

  return (
    <Layout>
      <Seo description={excerpt} image={Image} />
      <div className="home-banner grids">
        <h1 className="title">{frontmatter.title}</h1>
      </div>
      <EarlyPayoffCalculator />
      <div className="m-1" />
      <ValueProposition />
    </Layout>
  )
}

export default HomePage
